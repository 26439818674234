<template>
  <q-dialog
      v-model="isOpen"
      :maximized="false"
  >
    <q-card style="min-width: 50vw">
      <q-card-section class="row items-center">
        <div class="text-h6 text-center">
          {{ $t('You`ve selected products ') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-card-section v-if="selectedItem">
        <q-list bordered separator>
          <q-item>
            <q-item-section avatar>
              <img
                  style="max-height: 50px; width: 60px; object-fit: contain"
                  :src="item.image || fallbackImage"
                  @error="onImageLoadFailure"
              />
            </q-item-section>

            <q-item-section>
              {{ `${item.name} (${item.id})` }}
            </q-item-section>

            <q-item-section>
              {{ item._embedded.shop.name }}
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>
              <table class="table table-valign-middle">
                <thead>
                <tr>
                  <th>{{ $t('Status') }}</th>

                  <th>{{ $t('Amount') }}</th>

                  <th>{{ $t('Warehouse') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[selectedItem.state]}`">
                      {{ $t(stateName[selectedItem.state] || selectedItem.state) }}
                    </label>
                  </td>

                  <td>
                    <strong>{{ selectedItem.count }}</strong>
                  </td>

                  <td>{{ selectedItem.warehouse }}</td>
                </tr>
                </tbody>
              </table>
            </q-item-section>
          </q-item>

          <form-builder :schema="schema"/>
        </q-list>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="light-blue-9"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Move')"
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'OffersItemMove',
  emits: ['close'],
  components: {},
  data () {
    return {
      isOpen: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
      model: {
        warehouse: null,
        quantity: 0
      },
      item: {},
      selectedItem: {},
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked for Sales',
        booked: 'Booked',
        new: 'Received',
        shipped: 'Shipped'
      },
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
    }
  },
  computed: {
    ...mapGetters([
      'offers'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                wrapperStyleClasses: 'col-6 q-pa-xs',
                value: this.model.warehouse,
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' },
                      { type: 'neq', field: 'id', value: this.selectedItem.warehouse }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onChange: (warehouse) => {
                  this.model.warehouse = warehouse
                },
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'name',
                wrapperStyleClasses: 'col-6 q-pa-xs',
                value: this.model.quantity,
                required: true,
                label: this.$t('Quantity'),
                onChange: (quantity) => {
                  if (quantity > 0 && (quantity > this.selectedItem.count || quantity < 0)) {
                    this.model.quantity = 0

                    return;
                  }

                  this.model.quantity = quantity
                }
              }
            ],
          },
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    open (item) {
      this.isOpen = true
      this.item = item.offer
      this.selectedItem = item.item
    },
    hide () {
      this.isOpen = false
    },
    handleSelectType (row, type) {
      row.type = type.id
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    save () {
      if (!this.model.warehouse) {
        this.addErrorNotification('Warehouse not set!')

        return
      }

      let query = {
        per_page: this.model.quantity,
        filter: [
          { type: 'eq', field: 'warehouse', value: this.selectedItem.warehouse },
          { type: 'eq', field: 'state', value: this.selectedItem.state },
          { type: 'eq', field: 'productOffer', value: this.item.id },
        ]
      }

      const data = [{
        warehouse: this.model.warehouse?.id
      }]

      return this.$service.storageItemEntity.patch(data, query, 'application/json+offers').then((result) => {
        console.log(result)
      })
    }
  }
}
</script>
